export enum Gender {
  Male = 1,
  Female = 2,
  Transgender = 3,
}
export enum OccunpencyFactor{
  Self = 0,
  Rented = 1,
}
export enum RoleLevels{
Primary = 0,
Secondary = 1,
Intermediate = 2,
Authority = 3,
SupremeLevel = 4,
}
export enum Status{
Pending = 0,
Approved = 1,
Rejected = 2, 
}
export enum DefaultIC{
MUNICIPAL = 0, //muncipal
OPFT = 1, // 1.43
PTRATE = 2, //9% property tax rate
SELFDECLARE = 3,
CITY=4,
LOGO=5,
ISSUEDBY=6,
CONTACT=7,
URLONE=8,
URLTWO =9,
URLTHREE =10,
DEPARTMENT =11,
PARAGRAPH =12,
HEADINGONE =13,
HEADINGTWO =14,
HEADINGTHREE =15,
HEADINGFOUR =16,
HEADINGFIVE =17,  
STATEMENTONE= 18,
STATEMENTTWO =19,
STATEMENTTHREE =20,
STATEMENTFOUR =21,
STATEMENTFIVE =22,
STATEMENTSIX =23,
STATEMENTSEVEN =24,
STATEMENTEIGHT = 25,
STATEMENTNINE = 26,
STATEMENTTEN= 27,
STATEMENTELEVEN=28,
STATEMENTTWELEVE=29,
STATEMENTTHIRTEEN=30,
STATEMENTFOURTEEN=31,
STATEMENTFIFTEEN =32,
SUBHEADINGONE =33,
SUBHEADINGTWO=34,
SUBHEADINGTHREE=35,
SUBHEADINGFOUR=36,
SUBHEADINGFIVE=37,
ESTYEAR=38,
TRADELICENSEHEAD1=39,
TRADELICENSEHEAD2=40,
TRADELICENSEHEAD3=41,
TRADELICENSEHEAD4=42,
TRADELICENSEHEAD5=43,
TRADELICENSEHEAD6=44,
TRADELICENSEHEAD7=45,
TLLOGO=46,
TLSTATEMENTONE=47,
TLSTATEMENTTWO=48,
TLSTATEMENTTHREE=49,
 //Leatest 
 LATEST_TITLE_ONE = 50,
 LATEST_TITLE_TWO = 51,
 LATEST_TITLE_THREE = 52,
 LATEST_TITLE_FOUR = 53,
 LATEST_TITLE_FIVE = 54,
 LATEST_TITLE_SIX = 55,
 //HEADLINES
 HEADLINES_ONE_TITLE = 56,
 HEADLINES_ONE_DESCRIPTION = 57,
 HEADLINES_ONE_DATE = 58,
 HEADLINES_TWO_TITLE = 59,
 HEADLINES_TWO_DESCRIPTION = 60,
 HEADLINES_TWO_DATE = 61,
 HEADLINES_THREE_TITLE= 62,
 HEADLINES_THREE_DESCRIPTION = 63,
 HEADLINES_THREE_DATE = 64,
 HEADLINES_FOUR_TITLE = 65,
 HEADLINES_FOUR_DESCRIPTION = 66,
 HEADLINES_FOUR_DATE = 67,
 HEADLINES_FIVE_TITLE = 68,
 HEADLINES_FIVE_DESCRIPTION = 69,
 HEADLINES_FIVE_DATE = 70,
 HEADLINES_SIX_TITLE= 71,
 HEADLINES_SIX_DESCRIPTION = 72,
 HEADLINES_SIX_DATE = 73,
 HEADLINES_SEVEN_TITLE = 74,
 HEADLINES_SEVEN_DESCRIPTION = 75,
 HEADLINES_SEVEN_DATE = 76,
 HEADLINES_EIGHT_TITLE = 77,
 HEADLINES_EIGHT_DESCRIPTION = 78,
 HEADLINES_EIGHT_DATE = 79,
 HEADLINES_NINE_TITLE = 80,
 HEADLINES_NINE_DESCRIPTION = 81,
 HEADLINES_NINE_DATE = 82,
 HEADLINES_TEN_TITLE = 83,
 HEADLINES_TEN_DESCRIPTION = 84,
 HEADLINES_TEN_DATE = 85,
 HEADLINES_ELEVEN_TITLE = 86,
 HEADLINES_ELEVEN_DESCRIPTION = 87,
 HEADLINES_ELEVEN_DATE = 88,
 HEADLINES_TWALVE_TITLE = 89,
 HEADLINES_TWALVE_DESCRIPTION = 90,
 HEADLINES_TWALVE_DATE = 91,
 HEADLINES_THIRTEEN_TITLE = 92,
 HEADLINES_THIRTEEN_DESCRIPTION = 93,
 HEADLINES_THIRTEEN_DATE = 94,
 HEADLINES_FOURTEEN_TITLE = 95,
 HEADLINES_FOURTEEN_DESCRIPTION  = 96,
 HEADLINES_FOURTEEN_DATE = 97,
 HEADLINES_FIFTEEN_TITLE = 98,
 HEADLINES_FIFTEEN_DESCRIPTION = 99,
 HEADLINES_FIFTEEN_DATE = 100,
 //WHATS NEW
 WHATS_NEW,
 EVENT_PHOTOGRAPH_ONE,
 EVENT_PHOTOGRAPH_TWO,
 EVENT_PHOTOGRAPH_THREE,
 EVENT_PHOTOGRAPH_FOUR,
 EVENT_PHOTOGRAPH_FIVE,
 //NEWS
 NEWS_DATE_ONE,
 NEWS_DESCRIPTION_ONE,
 NEWS_DATE_TWO,
 NEWS_DESCRIPTION_TWO,
 NEWS_DATE_THREE,
 NEWS_DESCRIPTION_THREE,
 NEWS_DATE_FOUR,
 NEWS_DESCRIPTION_FOUR,
 NEWS_DATE_FIVE,
 NEWS_DESCRIPTION_FIVE,
 NEWS_DATE_SIX,
 NEWS_DESCRIPTION_SIX,
 NEWS_DATE_SEVEN,
 NEWS_DESCRIPTION_SEVEN,
 //MAP
 MAP_URL,
 //ABOUT US
 ABOUT_US,
 ABOUT_IMAGE_ONE,
 ABOUT_IMAGE_TWO,
 ABOUT_IMAGE_THREE,
 ABOUT_IMAGE_FOUR,
 ABOUT_IMAGE_FIVE,

 //CONTACT DETAILS
 CONTACT_NUMBER,
 EMAIL,
 ADDRESS,
 //TEAM IMAGES
 PERSON_NAME_ONE,
 PERSON_DESIGNATION_ONE,
 PERSON_PHOTOGRAPH_ONE,
 PERSON_ABOUT_ONE,
 PERSON_READMORE_LINK_ONE,
 PERSON_NAME_TWO,
 PERSON_DESIGNATION_TWO,
 PERSON_PHOTOGRAPH_TWO,
 PERSON_ABOUT_TWO,
 PERSON_READMORE_LINK_TWO,
 PERSON_NAME_THREE,
 PERSON_DESIGNATION_THREE,
 PERSON_PHOTOGRAPH_THREE,
 PERSON_ABOUT_THREE,
 PERSON_READMORE_LINK_THREE,
 INCLUDE_SOLIDWASTEMANAGEMENT_CHARGES, 
 INCLUDE_WATERUSAGE_CHARGES,
}
export enum floorsDetails{
GroundFloor = 0, 
FirstFloor = 1,
SecondFloor = 2,
ThirdFloor = 3,
FourthFloor = 4,
FifthFloor = 5,
}
export enum PaymentType{
NONE = 0,
MANUAL = 1,
CHEQUE =2,
ONLINE = 3,
}
export enum Quarters
{
  NONE = 0,
  First = 1,
  Second = 2,
  THIRD = 3,
  FOURTH = 4,
}
export enum PaymentAt{
  MUNICIPALITY =1,
  ONLINE,
  NOTIFIEDBANK,
  NOTIFIEDCIVICCENTERS
}
export enum PaymentMode{ 
  CASH =1,
  CHEQUE,
  DD,
  NEFT,
  RTGS,
  UPI,
  CARDPAYMENT
}

export enum PropertyStatus
{
  INITIATED,
  APPROVED,
  REJECTED
}
export enum Relation {
  SELF= 0,
  SONOF,
  WIFEOF,
  DAUGHTER,
  FATHEROF,
  MOTHEROF
}